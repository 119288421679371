import React, { useState } from "react"
import Layout from "../share/layout"
import BackgroundImage from 'gatsby-background-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Modal from 'react-bootstrap/Modal'
import arrow_next_min from '../../images/arrow_next-min.png'
import arrow_gold_back from '../../images/arrow_gold.png'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ControlHomePREV from '../../images/arrow_prev.png'
import ControlHomeNEXT from '../../images/arrow_next.png'
import 'react-h5-audio-player/lib/styles.css';
import { Link } from 'gatsby'
import Image from 'gatsby-image';
import { useLocation } from "@reach/router"
import ShareButton from "../share/ShareButton";


const Template3part2 = ({ pageContext }) => {
	const [Show, setShow] = useState(false)
	const QueryResultData = pageContext.data;

	let location = useLocation();

	var normalize = (function () {
		var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,",
			to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
			mapping = {};

		for (var i = 0, j = from.length; i < j; i++)
			mapping[from.charAt(i)] = to.charAt(i);

		return function (str) {
			var ret = [];
			for (var i = 0, j = str.length; i < j; i++) {
				var c = str.charAt(i);
				if (mapping.hasOwnProperty(str.charAt(i)))
					ret.push(mapping[c]);
				else if ((str[i] === " "))
					ret.push('-');
				else if ((str.length - 1 === i) && (str[str.length - 1] === " "))
					ret.push('-');
				else
					ret.push(c);
			}
			return ret.join('');
		}
	})();

	const clickSliderPREV = () => {
		if (document.querySelector('.control-prev')) {
			document.querySelector('.control-prev').click()
		}
	}

	const clickSliderNEXT = () => {
		if (document.querySelector('.control-next')) {
			document.querySelector('.control-next').click()
		}
	}

	return (
		<Layout
			descritpion={QueryResultData.subtitulo}
			title={QueryResultData.titulo}
			subtitile={QueryResultData.subtitulo}
			imgurl={QueryResultData.imagenDeFondoDeVideo === false ? QueryResultData.imagenesDeFondo[0].file.url : QueryResultData.fotoParaMostrarEnElMarco.file.url}
			metaTitle={QueryResultData.metaTitle}
			metaKeywords={QueryResultData.metaKeywords}
			metaDescripcin={QueryResultData.metaDescripcin}
		>

			{QueryResultData.existeVideoEnElMarco === true &&
				<Modal show={Show} onHide={() => { setShow(false) }}>
					<Modal.Header closeButton>
						<Modal.Title className="modal-title-video">{documentToReactComponents(QueryResultData.descripcionEnModal.json)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<iframe title={QueryResultData.titulo} width="100%" height="100%" src={QueryResultData.urlDelVideo}></iframe>
					</Modal.Body>
				</Modal>
			}
			<ShareButton url={location.pathname} colour={'rgb(200, 165, 60)'} />
			<div className="button-back">
				<Link to={`/galeria/${normalize(QueryResultData.idParaEnlazar).toLocaleLowerCase().trim()}`}><button>
					<img src={arrow_gold_back} alt="imagen de flecha" />
					<p>VOLVER AL INICIO</p>
				</button></Link>
			</div>
			<h1 className="d-none">{QueryResultData.titulo}</h1>
			<button onClick={clickSliderPREV} className="Button-Carousel-Next" style={{ outline: 'none' }}><img alt="imagen de flecha" className="button-control-prev-icon" src={ControlHomePREV} /></button>
			<button onClick={clickSliderNEXT} className="Button-Carousel-Prev" style={{ outline: 'none' }}><img alt="imagen de flecha" className="button-control-prev-icon" src={ControlHomeNEXT} /></button>
			<div className="template-type-1-part2">
				<Carousel autoPlay infiniteLoop interval={100000} transitionTime={1000} stopOnHover={false} showThumbs={false} className="carousel-template-2">
					{QueryResultData.imagenesDeFondo.map((imagenDeFondo, i) => (
						<BackgroundImage key={i} fluid={imagenDeFondo.fluid} className="background-image">
							<div className="gradient-image">
								<div className="carousel-text-caption">
									<div className="button-image-container" tabIndex="0" onClick={() => { if (QueryResultData.existeVideoEnElMarco === true) { setShow(!Show) } }} onKeyDown={(ev) => { if (ev.keyCode === 13) { if (QueryResultData.existeVideoEnElMarco === true) { setShow(!Show) } } }} role="button">
										{QueryResultData.existeVideoEnElMarco === true &&
											<React.Fragment>
												<div className="button-image-container-title">
													<div className="play-container"><img alt="imagen de flecha" src={arrow_next_min} /></div>
													<p>Mira el video</p>
												</div>

												<div className="img-container-play">
													<i className="fa fa-play-circle"></i>
												</div>
											</React.Fragment>
										}
										<Image className="img-marco" fluid={QueryResultData.fotoParaMostrarEnElMarco.fluid} alt={QueryResultData.fotoParaMostrarEnElMarco.description ? QueryResultData.fotoParaMostrarEnElMarco.description : QueryResultData.fotoParaMostrarEnElMarco.title} />
									</div>
									<div className="carousel-text-container">
										<div className="carousel-title-container">

											<div className="button-image-container button-image-container-responsive" tabIndex="0" onClick={() => { if (QueryResultData.existeVideoEnElMarco === true) { setShow(!Show) } }} onKeyDown={(ev) => { if (ev.keyCode === 13) { if (QueryResultData.existeVideoEnElMarco === true) { setShow(!Show) } } }} role="button">
												{QueryResultData.existeVideoEnElMarco === true &&
													<React.Fragment>
														<div className="button-image-container-title">
															<div className="play-container"><img alt="imagen de flecha" src={arrow_next_min} /></div>
															<p>Mira el video</p>
														</div>
														<div className="img-container-play">
															<i className="fa fa-play-circle"></i>
														</div>
													</React.Fragment>
												}
												<Image className="img-marco" fluid={QueryResultData.fotoParaMostrarEnElMarco.fluid} alt={QueryResultData.fotoParaMostrarEnElMarco.description ? QueryResultData.fotoParaMostrarEnElMarco.description : QueryResultData.fotoParaMostrarEnElMarco.title} />
											</div>
											<div className="carousel-title-subcontainer">
												<h6>{QueryResultData.titulo}</h6>
												{pageContext.data.subtitulo !== "-" && <h2>{QueryResultData.subtitulo}</h2>}
											</div>
										</div>
										<div className="carousel-description-container">
											<div className="vertical_line"></div>
											<div className="carousel-description-subcontainer">
												{i === 0 && <React.Fragment>{documentToReactComponents(QueryResultData.descripcion.json)}</React.Fragment>}
												{i === 1 && <React.Fragment>{documentToReactComponents(QueryResultData.segundaDescripcion.json)}</React.Fragment>}
												{i === 2 && <React.Fragment>{documentToReactComponents(QueryResultData.terceraDescripcion.json)}</React.Fragment>}
												{i === 3 && <React.Fragment>{documentToReactComponents(QueryResultData.cuartaDescripcion.json)}</React.Fragment>}
												{i === 4 && <React.Fragment>{documentToReactComponents(QueryResultData.quintaDescripcion.json)}</React.Fragment>}
												{i === 5 && <React.Fragment>{documentToReactComponents(QueryResultData.sextaDescripcion.json)}</React.Fragment>}
												{i === 6 && <React.Fragment>{documentToReactComponents(QueryResultData.septimaDescripcion.json)}</React.Fragment>}
												{i === 7 && <React.Fragment>{documentToReactComponents(QueryResultData.octavaDescripcion.json)}</React.Fragment>}
												{i === 8 && <React.Fragment>{documentToReactComponents(QueryResultData.novenaDescripcion.json)}</React.Fragment>}
												{i === 9 && <React.Fragment>{documentToReactComponents(QueryResultData.decimaDescripcion.json)}</React.Fragment>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</BackgroundImage>
					))}
				</Carousel>
			</div>
		</Layout>
	)
}
export default Template3part2
